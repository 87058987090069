/* You can add global styles to this file, and also import other style files */

body {
  background-color: #f1f1f1;
  padding-bottom: 170px;
}

h1 {
  font-size: 26px;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 12px;
}

* {
  font-family: 'Inter', sans-serif;
}
